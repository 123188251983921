import Vue from 'vue';
import { onMounted, ref } from '@vue/composition-api';
import vuetify from '@/config/plugins/vuetify';
import { Debug } from '@/shared/utils/helpers';
import { toggle } from '@/shared/utils/vuex';

const FooterSection = Vue.extend({
    setup() {
        onMounted(() => {
            Debug.log('Footer mounted');
        });
        const dialog = ref(false);
        const toggleDig = () => {
            dialog.value = !dialog.value;
        };
        return {
            dialog,
            toggleDig,
        };
    },
});
export default new FooterSection({
    el: '#footer',
    vuetify,
    delimiters: ['[{', '}]'],
});
