import Vue from 'vue';
import Vuetify, { VBtn, VIcon, VTextField, VTextarea, VSelect, VContainer, VNavigationDrawer, VFabTransition, VDialog, VCard, VDivider, VSnackbar, VCarousel, VCarouselItem, VApp, VCardTitle, VCardText, VCardActions, VSpacer } from 'vuetify/lib';
import es from 'vuetify/src/locale/es';

Vue.use(Vuetify, {
  components: {
    VBtn,
    VCard,
    VIcon,
    VTextField,
    VTextarea,
    VSelect,
    VContainer,
    VNavigationDrawer,
    VFabTransition,
    VDialog,
    VDivider,
    VSnackbar,
    VCarousel,
    VCarouselItem,
    VApp,
    VCardTitle,
    VCardText,
    VCardActions,
    VSpacer,
  },
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0052D3',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdi',
  },
});
