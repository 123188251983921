import Vue from 'vue';
import { onMounted, computed } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { data } from '@/observable';
const FooterSection = Vue.extend({
    setup() {
        onMounted(() => {
            Debug.log('Header mounted');
        });
        const count = computed(() => data.counter);
        return {
            count,
        };
    },
});
export default new FooterSection({
    el: '#sticky-header',
    delimiters: ['[{', '}]'],
});
