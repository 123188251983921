





























import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { onMounted, ref, reactive, computed } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { data } from '@/observable';
import { mdiAccount } from '@mdi/js';
import * as rules from 'monkey-validates-form';
export default Vue.extend({
  name: 'Formulario',
  props: {
    info: Object,
  },
  setup(props, {emit}) {
    const item = props.info;
    const formContacto = ref(null);
    const valid = ref(false);
    const state: any = reactive({
      form: 'suscripcion',
      email: '',
    });
    const respuesta = reactive({
      texto: '',
      type: 'error',
      visible: false,
      spinner: false,
    });
    const enviar = (e: Event) => {
      e.preventDefault();
      if ((formContacto.value as any).validate()) {
        respuesta.spinner = true;
        const formdata = new FormData();
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        for (const key in state) {
            formdata.append(key, state[key]);
        }
        axios.post('/assets/php/prueba.php', formdata, config)
            .then((response) => {
                (formContacto.value as any).reset();
                respuesta.texto = response.data.mensaje;
                respuesta.type = 'success';
            }).catch((error) => {
                respuesta.texto = 'Error al enviar el mensaje';
                respuesta.type = 'error';
            }).finally(() => {
                respuesta.visible = true;
                respuesta.spinner = false;
            });
      }
    };
    onMounted(() => {
      Debug.log(item);
      // Debug.log(props.data);
    });
    return {
        item,
        valid,
        formContacto,
        state,
        rules,
        respuesta,
        enviar,
    };
  },
});
